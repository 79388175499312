import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import Video from 'components/Video'

const Block = ({ content, locale }) => {
  const settings = {
    controls: true,
  }
  return (
    <div className={styles.component}>
      <Video content={content} settings={settings} locale={locale} />
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment VideoFullEn on MarkdownRemarkFrontmatterEnBlocks {
    videoSlug
  }
`
export const fragmentZh = graphql`
  fragment VideoFullZh on MarkdownRemarkFrontmatterZhBlocks {
    videoSlug
  }
`
