import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import GridVideos from 'blocks/GridVideos'

const Block = ({ content, data, locale }) => {
  const videoPosts = data?.videoPosts?.nodes
  const videoPostsLocale = videoPosts?.map((item) => item.frontmatter[locale])
  const videos = data?.videos?.nodes
  const videosLocale = videos?.map((item) => item.frontmatter[locale])

  const gridContent = {
    title: content.title,
    items: videoPostsLocale,
    videos: videosLocale,
  }

  return <GridVideos content={gridContent} locale={locale} />
}

export default function Data({ content, data, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          videoPosts: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "videoPost" } } }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  videoSlug
                }
                zh {
                  title
                  image {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  videoSlug
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}

export const fragmentEn = graphql`
  fragment VideoPostGridEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment VideoPostGridZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
