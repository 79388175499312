import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { text, items } = content
  return (
    <Inview className={styles.welcome}>
      <Text content={text} />
      <Items content={items} />
    </Inview>
  )
}

const Text = ({ content }) => {
  const { title, body } = content
  return (
    <div className={styles.accueilText}>
      <div>
        <h2
          className={`${styles.presentationTitre} ${styles.slideRight} in in-slide-right in-fade-in`}
        >
          {title}
        </h2>
        <div
          className={`${styles.presentation} ${styles.slideRight} in in-slide-right in-fade-in`}
        >
          <MarkdownWrap body={body} />
        </div>
      </div>
    </div>
  )
}

const Items = ({ content }) => {
  return (
    <div className={styles.welcomeImgWrapper}>
      {content.map((item, i) => {
        const { title, image } = item
        return (
          <div
            key={i}
            className={`${styles.welcomeImg} ${styles.animatedItem} ${
              styles.slideLeft
            } in in-slide-left in-fade-in stagger-${500 + 500 * i}`}
          >
            <div className={styles.image}>
              <ImageWrap image={image} />
            </div>

            <h3>{title}</h3>
          </div>
        )
      })}
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextPlus3En on MarkdownRemarkFrontmatterEnBlocks {
    text {
      title
      body
    }
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment TextPlus3Zh on MarkdownRemarkFrontmatterZhBlocks {
    text {
      title
      body
    }
    items {
      title
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
