import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { title, body, logo } = content
  return (
    <Inview className={styles.customer}>
      <div className='container'>
        <div className='row'>
          <div className='col-title col-12'>
            <h1 className='in in-fade-in-up-100'>{title}</h1>
          </div>
          <div className='col-description col-12'>
            <MarkdownWrap body={body} />
          </div>
          <div
            className={`offset-1 col-10 ${styles.colLogo} in in-fade-in-up-100 stagger-200`}
          >
            <div className={styles.image}>
              <ImageWrap image={logo} />
            </div>
          </div>
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextLogoEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    body
    logo {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment TextLogoZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    body
    logo {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
