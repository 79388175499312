// extracted by mini-css-extract-plugin
export var active = "styles-module--active--JuTjZ";
export var brandIntro = "styles-module--brand-intro--+jKSM";
export var brandLink = "styles-module--brand-link--KNqYa";
export var brandSchema = "styles-module--brand-schema--Wufxp";
export var divide = "styles-module--divide--ipGNw";
export var headerProduct = "styles-module--header-product--2nvQg";
export var imageMobile = "styles-module--image-mobile--rGTzK";
export var midContent = "styles-module--mid-content--qCc2V";
export var onglets = "styles-module--onglets--UMVrE";
export var products = "styles-module--products--fsp3Q";
export var rightContent = "styles-module--right-content--14ezr";