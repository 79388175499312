import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { title } = content

  const block = useRef()

  const scrollToNextBlock = () => {
    const getBlock = block.current // ref
    const blockBottomY = getBlock.offsetTop + getBlock.offsetHeight
    window.scrollTo({
      top: blockBottomY,
      behavior: 'smooth',
    })
  }

  return (
    <div className={`${styles.more} more`} ref={block}>
      <h3>{title}</h3>
      <a onClick={scrollToNextBlock}>
        <i className='fal fa-chevron-down'></i>
      </a>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment MoreEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment MoreZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
