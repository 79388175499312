import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { title, body, image } = content
  return (
    <Inview className={styles.headerProduct}>
      <div className={`${styles.imageBg} in in-fade-in`}>
        <ImageWrap image={image} />
      </div>
      <div className={styles.headerProductContent}>
        <h1 className='in in-fade-in-down-more'>{title}</h1>
        <div className='in in-fade-in-down-more stagger-200'>
          <MarkdownWrap body={body} />
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment BannerTextImageEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment BannerTextImageZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    body
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
