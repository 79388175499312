// extracted by mini-css-extract-plugin
export var accueilText = "styles-module--accueil-text---Z7yF";
export var animatedItem = "styles-module--animated-item--v7ERb";
export var image = "styles-module--image--jpt2z";
export var presentation = "styles-module--presentation--Fs+Ux";
export var presentationTitre = "styles-module--presentation-titre--xjcJP";
export var slideLeft = "styles-module--slide-left--415J1";
export var slideRight = "styles-module--slide-right--ytPM1";
export var welcome = "styles-module--welcome--+WRPv";
export var welcomeImg = "styles-module--welcome-img--ckgiI";
export var welcomeImgWrapper = "styles-module--welcome-img-wrapper--HwNNf";