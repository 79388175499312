import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './styles.module.scss'

SwiperCore.use([Autoplay])

const Slideshow = ({ content }) => {
  const { items } = content

  return (
    <div className={`${styles.component}`}>
      <Swiper
        loop={true}
        speed={3000}
        dir='rtl'
        autoplay={{
          delay: 0,
        }}
        allowTouchMove={false}
      >
        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            <ImageWrap image={item.image} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slideshow
