import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { image } = content
  return (
    <Inview className={styles.headerContact}>
      <div className='in in-fade-in'>
        <ImageWrap image={image} />
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment ImageFullEn on MarkdownRemarkFrontmatterEnBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment ImageFullZh on MarkdownRemarkFrontmatterZhBlocks {
    image {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
