import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import ModalVideo from 'components/ModalVideo'
import { StaticImage } from 'gatsby-plugin-image'
import SimpleSlideshow from 'components/Slideshows/SimpleSlideshow'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { items, slideshow } = content
  return (
    <div className={styles.component}>
      <Items content={items} locale={locale} />
      <Slideshow content={slideshow} />
    </div>
  )
}

const Items = ({ content, locale }) => {
  return (
    <div className={styles.projectItems}>
      {content.map((item, i) => (
        <Item content={item} key={i} locale={locale} />
      ))}
    </div>
  )
}

const Item = ({ content, locale }) => {
  const { title, subtitle, body, image, videos } = content
  return (
    <Inview className={`${styles.projectItem} in in-fade-in`}>
      <div className={styles.projectItemThumb}>
        <ImageWrap image={image} />
      </div>
      <div className={styles.projectItemContent}>
        <h2>{title}</h2>
        {subtitle && <h3>{subtitle}</h3>}
        <MarkdownWrap body={body} />
      </div>
      {videos?.length && (
        <div className={styles.projectItemButtons}>
          {videos.map((item, i) => {
            return (
              <div className={styles.videoButton} key={i}>
                <StaticImage
                  src='../../assets/images/video-button.png'
                  alt=''
                />
                <ModalVideo content={item} locale={locale} />
              </div>
            )
          })}
        </div>
      )}
    </Inview>
  )
}

const Slideshow = ({ content }) => {
  const { slides } = content
  return (
    <div className='projects-slide'>
      <div className={styles.cycleSlideshow}>
        <SimpleSlideshow content={{ items: slides }} />
      </div>
      {/* <div
        id='cycle-container'
        className={`${styles.cycleSlideshow} cycle-slideshow-m`}
        data-cycle-fx='scrollHorz'
        data-cycle-timeout='1'
        data-cycle-speed='3000'
        data-cycle-reverse='true'
        data-cycle-pause-on-hover='true'
        style={{ overflow: 'hidden' }}
      > */}
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment ListVideoItemsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    items {
      title
      subtitle
      body
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videos {
        videoSlug
      }
    }
    slideshow {
      slides {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment ListVideoItemsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    items {
      title
      subtitle
      body
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
      videos {
        videoSlug
      }
    }
    slideshow {
      slides {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
