import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'

const Block = ({ content }) => {
  const { title, items } = content
  return (
    <div className={styles.headerResearchFocus}>
      <h2>{title}</h2>
      <ul className={styles.focusItems}>
        {items.map((item, i) => (
          <Item content={item} key={i} />
        ))}
      </ul>
    </div>
  )
}

const Item = ({ content }) => {
  const { image } = content
  return (
    <li className={styles.focusItem}>
      <ImageWrap image={image} />
    </li>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridImagesSmallerEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    items {
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment GridImagesSmallerZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    items {
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
