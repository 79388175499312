import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { title, body, logo, image } = content
  return (
    <Inview className={styles.headerTop}>
      <div className={styles.headerTopText}>
        <div className={`${styles.logoHeader} in in-fade-in-right`}>
          <ImageWrap image={logo} />
        </div>
        <h1 className='in in-fade-in-right stagger-100'>{title}</h1>
        <div className='in in-fade-in-right stagger-200'>
          <MarkdownWrap body={body} />
        </div>
      </div>
      <div
        className={`${styles.headerTopBanner} in in-fade-in-left stagger-600`}
      >
        <ImageWrap image={image} />
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment BannerImageTextInsetEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment BannerImageTextInsetZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
