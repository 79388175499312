import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import ModalVideo from 'components/ModalVideo'
import { StaticImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { items } = content
  return (
    <section className={styles.researchContent}>
      {items.map((item, i) => (
        <Item content={item} key={i} locale={locale} />
      ))}
    </section>
  )
}

const Item = ({ content, locale }) => {
  const { title, body, body2, note, videoSlug } = content
  const titleSpan = title.replace(`: `, `: <br />`)
  return (
    <Inview className={`${styles.researchContentItem} in in-fade-in`}>
      <div className={styles.researchContentItemTitle}>
        <h2 dangerouslySetInnerHTML={{ __html: titleSpan }} />
      </div>
      <div className={styles.researchContentItemText}>
        <div>
          {body && <MarkdownWrap body={body} />}
          {videoSlug && <VideoButton content={{ videoSlug }} locale={locale} />}
          {/* {body && !videoSlug && (
            <a className='d-xs-block'>
              More detail<i className='fal fa-chevron-right'></i>
            </a>
          )} */}
        </div>
        <div>
          {body2 && <MarkdownWrap body={body2} />}
          {note && (
            <div className={styles.europe}>
              <div className={styles.image}>
                <StaticImage src='../../assets/images/eu-flag.jpeg' alt='' />
              </div>
              <MarkdownWrap body={note} />
            </div>
          )}
        </div>
      </div>
    </Inview>
  )
}

const VideoButton = ({ content, locale }) => {
  return (
    <div className={styles.videoButton}>
      <div className={styles.inner}>
        <StaticImage src='../../assets/images/video-button.png' alt='' />
        <ModalVideo content={content} locale={locale} />
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment ListArticleItemsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment ListArticleItemsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
