import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import MarkdownWrap from 'components/MarkdownWrap'
import ParallaxWrap from 'components/ParallaxWrap'

const Block = ({ content }) => {
  const { top, bottom } = content
  return (
    <section className={styles.about}>
      <Top content={top} />
      <Bottom content={bottom} />
    </section>
  )
}

const Top = ({ content }) => {
  const { title, body, logo, image } = content
  return (
    <div className={`${styles.wrapper} ${styles.wrapperTop} block`}>
      <div className={styles.contentLeft}>
        <h1>{title}</h1>
        <div className={styles.logo}>
          <ImageWrap image={logo} />
        </div>
        <MarkdownWrap body={body} />
      </div>
      <div className={`${styles.image} ${styles.imageTop} ${styles.desktop} `}>
        <ParallaxWrap>
          <ImageWrap image={image} />
        </ParallaxWrap>
      </div>
      <div className={`${styles.image} ${styles.mobile}`}>
        <ImageWrap image={image} />
      </div>
    </div>
  )
}

const Bottom = ({ content }) => {
  const { body, image } = content
  return (
    <div className={`${styles.wrapper} block`}>
      <div className={styles.contentRight}>
        <MarkdownWrap body={body} />
      </div>
      <div
        className={`${styles.image} ${styles.imageBottom} ${styles.desktop} `}
      >
        <ParallaxWrap>
          <ImageWrap image={image} />
        </ParallaxWrap>
      </div>
      <div className={`${styles.image} ${styles.mobile}`}>
        <ImageWrap image={image} />
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TextImageFullEn on MarkdownRemarkFrontmatterEnBlocks {
    top {
      title
      body
      logo {
        childImageSharp {
          gatsbyImageData
        }
      }
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    bottom {
      body
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment TextImageFullZh on MarkdownRemarkFrontmatterZhBlocks {
    top {
      title
      body
      logo {
        childImageSharp {
          gatsbyImageData
        }
      }
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    bottom {
      body
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
