import React from 'react'
import { graphql } from 'gatsby'
import Seo from 'components/Seo'
import Footer from 'components/Footer'
import * as styles from './styles.module.scss'

// IMPORTING BLOCKS
// 1) add to imports
// 2) add to components object
// 3) add to graphQL

import BannerHome from 'blocks/BannerHome'
import BannerImageTextInset from 'blocks/BannerImageTextInset'
import BannerTextImage from 'blocks/BannerTextImage'
import GridIcons from 'blocks/GridIcons'
import GridTextColumns from 'blocks/GridTextColumns'
import GridImagesSmaller from 'blocks/GridImagesSmaller'
import ImageFull from 'blocks/ImageFull'
import ListDiagramItems from 'blocks/ListDiagramItems'
import ListVideoItems from 'blocks/ListVideoItems'
import More from 'blocks/More'
import TextImageFull from 'blocks/TextImageFull'
import TextLogo from 'blocks/TextLogo'
import TextPlus3 from 'blocks/TextPlus3'
import TermsPage from 'blocks/TermsPage'
import VideoFull from 'blocks/VideoFull'
import ArticleList from 'blocks/_dynamic/ArticleList'
import NewsFeaturedBanner from 'blocks/_dynamic/NewsFeaturedBanner'
import NewsGrid from 'blocks/_dynamic/NewsGrid'
import ProductItems from 'blocks/_dynamic/ProductItems'
import VideoPostGrid from 'blocks/_dynamic/VideoPostGrid'

// [name of block type]: [Imported Component]
// ['TextCentered']: TextCentered

const components = {
  BannerHome,
  BannerImageTextInset,
  BannerTextImage,
  GridIcons,
  GridTextColumns,
  GridImagesSmaller,
  ImageFull,
  ListDiagramItems,
  ListVideoItems,
  More,
  TextImageFull,
  TextLogo,
  TextPlus3,
  TermsPage,
  VideoFull,
  ArticleList,
  NewsFeaturedBanner,
  NewsGrid,
  ProductItems,
  VideoPostGrid,
}

const ContentType = (props) => {
  const { data, pageContext, layoutProps } = props
  const { locale } = pageContext
  const { page } = data
  const content = page.frontmatter[locale]
  return (
    <>
      <Seo content={content} locale={locale} />
      <PageTemplate
        content={content}
        locale={locale}
        layoutProps={layoutProps}
      />
    </>
  )
}

// from CMS: go directly to Page Template

const PageTemplate = ({ content, locale, layoutProps }) => {
  const { blocks } = content
  return (
    <div className={`${styles.page} page`}>
      {blocks?.map((block, i) => {
        if (components[block.type]) {
          return React.createElement(components[block.type], {
            content: block,
            locale,
            layoutProps,
            key: i,
          })
        }
      })}
      <Footer locale={locale} />
    </div>
  )
}

export default ContentType

export const pageQuery = graphql`
  query ($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        en {
          title
          blocks {
            type
            ...BannerHomeEn
            ...BannerImageTextInsetEn
            ...BannerTextImageEn
            ...GridIconsEn
            ...GridTextColumnsEn
            ...GridImagesSmallerEn
            ...ImageFullEn
            ...ListArticleItemsEn
            ...ListDiagramItemsEn
            ...ListVideoItemsEn
            ...MoreEn
            ...TextImageFullEn
            ...TextLogoEn
            ...TextPlus3En
            ...TermsPageEn
            ...VideoFullEn
            ...VideoPostGridEn
          }
          seo {
            title
            description
            keywords
          }
        }
        zh {
          title
          blocks {
            type
            ...BannerHomeZh
            ...BannerImageTextInsetZh
            ...BannerTextImageZh
            ...GridIconsZh
            ...GridTextColumnsZh
            ...GridImagesSmallerZh
            ...ImageFullZh
            ...ListArticleItemsZh
            ...ListDiagramItemsZh
            ...ListVideoItemsZh
            ...MoreZh
            ...TextImageFullZh
            ...TextLogoZh
            ...TextPlus3Zh
            ...TermsPageZh
            ...VideoFullZh
            ...VideoPostGridZh
          }
          seo {
            title
            description
            keywords
          }
        }
      }
    }
  }
`
