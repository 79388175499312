import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ListArticleItems from 'blocks/ListArticleItems'

const Block = ({ content, data, locale }) => {
  const articles = data?.articles?.nodes
  const articlesLocale = articles?.map((item) => item.frontmatter[locale])

  const listContent = {
    items: articlesLocale,
  }

  return <ListArticleItems content={listContent} locale={locale} />
}

export default function Data({ content, data, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          articles: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "article" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  body
                  body2
                  note
                  videoSlug
                }
                zh {
                  slug
                  title
                  body
                  body2
                  note
                  videoSlug
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
