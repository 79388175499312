// extracted by mini-css-extract-plugin
export var about = "styles-module--about--PKHdl";
export var contentLeft = "styles-module--content-left--U45mE";
export var contentRight = "styles-module--content-right--RgcF2";
export var desktop = "styles-module--desktop--HGljF";
export var image = "styles-module--image--q6aZs";
export var imageBottom = "styles-module--image-bottom--LZ3I0";
export var imageTop = "styles-module--image-top--vBaZB";
export var logo = "styles-module--logo--jsnt9";
export var mobile = "styles-module--mobile--U4Ojq";
export var wrapper = "styles-module--wrapper--kzOUM";
export var wrapperTop = "styles-module--wrapper-top--NYGsr";