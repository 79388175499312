import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BannerPost from 'blocks/BannerPost'

const Block = ({ content, data, locale }) => {
  const newsPosts = data?.newsPosts?.nodes
  const newsPostsLocale = newsPosts?.map((item) => item.frontmatter[locale])
  const featuredNewsPost = newsPostsLocale[0]
  if (!featuredNewsPost) return false

  const bannerContent = {
    slug: featuredNewsPost.slug,
    title: featuredNewsPost.title,
    image: featuredNewsPost.brief?.imageBanner,
  }

  return <BannerPost content={bannerContent} locale={locale} />
}

export default function Data({ content, data, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          newsPosts: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: {
                  templateKey: { eq: "newsPost" }
                  brief: { isFeatured: { eq: true } }
                }
              }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  brief {
                    imageBanner {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                zh {
                  slug
                  title
                  brief {
                    imageBanner {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
