import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
// import { StaticImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { items } = content
  const items1 = items.slice(0, 3)
  const items2 = items.length > 3 ? items.slice(3, items.length) : null

  return (
    <Inview className={`mx-0 is-visible ${styles.servicesAccueil}`}>
      {items1.map((item, i) => (
        <Item content={item} key={i} i={i} />
      ))}
      {/* {items2 && (
        <div className={styles.divider}>
          <StaticImage src='../../assets/images/fire-cn.png' alt='' />
        </div>
      )} */}
      {items2 &&
        items2.map((item, i) => <Item content={item} key={i} i={i + 3} />)}
    </Inview>
  )
}

const Item = ({ content, i }) => {
  const { title, excerpt, image } = content
  return (
    <div className={`${styles.servItem} in in-fade-in-up stagger-${i * 500}`}>
      <div className={styles.image}>
        <ImageWrap image={image} />
      </div>
      <h4>{title}</h4>
      <p>{excerpt}</p>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridIconsEn on MarkdownRemarkFrontmatterEnBlocks {
    items {
      title
      excerpt
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
export const fragmentZh = graphql`
  fragment GridIconsZh on MarkdownRemarkFrontmatterZhBlocks {
    items {
      title
      excerpt
      image {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`
