import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import GridThumbs from 'blocks/GridThumbs'

const Block = ({ content, data, locale }) => {
  const newsPosts = data?.newsPosts?.nodes
  const newsPostsLocale = newsPosts?.map((item) => item.frontmatter[locale])
  const gridContent = {
    items: newsPostsLocale.map((item) => {
      return {
        slug: item.slug,
        title: item.title,
        image: item.brief?.image,
      }
    }),
  }

  return <GridThumbs content={gridContent} />
}

export default function Data({ content, data, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          newsPosts: allMarkdownRemark(
            filter: {
              frontmatter: {
                en: {
                  templateKey: { eq: "newsPost" }
                  brief: { isFeatured: { ne: true } }
                }
              }
            }
            sort: { fields: frontmatter___en___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                }
                zh {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}

// brief {
//   image {
//     childImageSharp {
//       gatsbyImageData
//     }
//   }
// }
