import React, { useState } from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import LinkWrap from 'components/LinkWrap'
import ImageWrap from 'components/ImageWrap'
import { textAddSpans } from 'js/utils'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { items, categories } = content
  const [activeCat, setActiveCat] = useState(categories[0].slug)

  return (
    <Inview>
      <Select
        content={{ categories }}
        activeCat={activeCat}
        setActiveCat={setActiveCat}
      />
      <section className={styles.products}>
        {items.map((item, i) => (
          <Item content={item} key={i} activeCat={activeCat} locale={locale} />
        ))}
      </section>
    </Inview>
  )
}

const Select = ({ content, activeCat, setActiveCat }) => {
  const { categories } = content
  return (
    <div className={styles.headerProduct}>
      <div className={styles.onglets}>
        <ul>
          {categories.map((item, i) => {
            const { title } = item
            const titleSpan = textAddSpans(title)
            const activeClass = item.slug === activeCat ? styles.active : ''
            return (
              <li
                className={`product-tab-item link-all ${activeClass} in in-fade-in-up-less stagger-${
                  400 + i * 100
                }`}
                key={i}
                dangerouslySetInnerHTML={{ __html: titleSpan }}
                onClick={() => setActiveCat(item.slug)}
              />
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const Item = ({ content, activeCat, locale }) => {
  const { slug, title, brief, productCategorySlugs } = content
  const divideClass = brief.points.length === 2 ? styles.divide : ''
  const titleSpan = textAddSpans(title)
  const activeClass = productCategorySlugs.includes(activeCat)
    ? styles.active
    : ''

  const strings = {
    en: {
      moreDetail: 'More Detail',
    },
    zh: {
      moreDetail: '更多详情',
    },
  }

  return (
    <article className={activeClass}>
      <div className={styles.brandIntro}>
        <h2
          className='in in-fade-in-up-100 stagger-1000'
          dangerouslySetInnerHTML={{ __html: titleSpan }}
        />
        <div className='in in-fade-in-up-100 stagger-1100'>
          <MarkdownWrap body={brief.body} />
        </div>
      </div>
      <div className={styles.brandSchema}>
        <div
          className={`${styles.midContent} in in-fade-in-up-100 stagger-1200`}
        >
          <div className='d-none d-sm-block'>
            <ImageWrap image={brief.image} />
          </div>
          <div className='d-sm-none'>
            <div className={styles.imageMobile}>
              <ImageWrap image={brief.imageMobile} />
            </div>
          </div>
        </div>
        <div
          className={`${styles.rightContent} ${divideClass} in in-fade-in-up-100 stagger-1300`}
        >
          {brief.points.map((point, i) => (
            <div key={i}>
              <h3>{point.subtitle}</h3>
              <p>{point.excerpt}</p>
            </div>
          ))}
        </div>
      </div>
      <div className={`${styles.brandLink} in in-fade-in stagger-1500`}>
        <LinkWrap to={`/products/${slug}`}>
          {strings[locale].moreDetail}
          <i className='fal fa-chevron-right'></i>
        </LinkWrap>
      </div>
    </article>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment ListDiagramItemsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment ListDiagramItemsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
