import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ListDiagramItems from 'blocks/ListDiagramItems'

const Block = ({ content, data, locale }) => {
  const products = data?.products?.nodes
  const productsLocale = products?.map((item) => item.frontmatter[locale])
  const productCategories = data?.productCategories?.nodes
  const productCategoriesLocale = productCategories?.map(
    (item) => item.frontmatter[locale]
  )

  const listContent = {
    items: productsLocale,
    categories: productCategoriesLocale,
  }

  return <ListDiagramItems content={listContent} locale={locale} />
}

export default function Data({ content, data, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          products: allMarkdownRemark(
            filter: { frontmatter: { en: { templateKey: { eq: "product" } } } }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                  productCategorySlugs
                  brief {
                    body
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    points {
                      subtitle
                      excerpt
                    }
                  }
                }
                zh {
                  slug
                  title
                  productCategorySlugs
                  brief {
                    body
                    image {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    imageMobile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    points {
                      subtitle
                      excerpt
                    }
                  }
                }
              }
            }
          }
          productCategories: allMarkdownRemark(
            filter: {
              frontmatter: { en: { templateKey: { eq: "productCategory" } } }
            }
            sort: { fields: frontmatter___en___order, order: ASC }
          ) {
            nodes {
              frontmatter {
                en {
                  slug
                  title
                }
                zh {
                  slug
                  title
                }
              }
            }
          }
        }
      `}
      render={(data) => <Block content={content} data={data} locale={locale} />}
    />
  )
}
