import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'

const Block = ({ content }) => {
  const { title, body } = content
  return (
    <div className={styles.component}>
      <div className='container'>
        <div className={styles.title}>
          <h1>{title}</h1>
        </div>
        <div className={styles.body}>
          <MarkdownWrap body={body} />
        </div>
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment TermsPageEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    body
  }
`
export const fragmentZh = graphql`
  fragment TermsPageZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    body
  }
`
