import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import MarkdownWrap from 'components/MarkdownWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { title, items } = content
  return (
    <Inview className={styles.contact}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='in in-fade-in-down-100 stagger-200'>{title}</h1>
          </div>
        </div>
        <div className={styles.contactsContainer}>
          {items.map((item, i) => (
            <Item content={item} key={i} i={i} />
          ))}
        </div>
      </div>
    </Inview>
  )
}

const Item = ({ content, i }) => {
  const { title, body } = content
  return (
    <div
      className={`${styles.contactItem} in in-fade-in-down-100 stagger-${
        400 + i * 100
      }`}
    >
      <h3>{title}</h3>
      <div className={styles.body}>
        <MarkdownWrap body={body} />
      </div>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridTextColumnsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
    items {
      title
      body
    }
  }
`
export const fragmentZh = graphql`
  fragment GridTextColumnsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
    items {
      title
      body
    }
  }
`
