import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'

const Block = ({ content, locale }) => {
  const { slug, title, image } = content
  const titleShorter = title.slice(0, 80) + '...'
  const strings = {
    en: {
      readOn: 'Read on',
    },
    zh: {
      readOn: '阅读详情',
    },
  }
  return (
    <Inview className={styles.firstNews}>
      <div className={styles.image}>
        <ImageWrap image={image} />
      </div>
      <div className={styles.firstNewsContent}>
        <h1 className='in in-fade-in-right'>{titleShorter}</h1>
        <p className='in in-fade-in-right stagger-200'>{titleShorter}</p>
        <div className='in in-fade-in stagger-500'>
          <LinkWrap to={`/news/${slug}`}>
            {strings[locale].readOn}
            <i className='fal fa-chevron-right'></i>
          </LinkWrap>
        </div>
      </div>
    </Inview>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment BannerPostEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment BannerPostZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
