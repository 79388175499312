import React from 'react'
import { graphql } from 'gatsby'
import * as styles from './styles.module.scss'
import LinkWrap from 'components/LinkWrap'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'

const Block = ({ content }) => {
  const { items } = content
  return (
    <Inview className={styles.news}>
      <div className={styles.newsContainer}>
        {items.map((item, i) => (
          <Item content={item} key={i} i={i} />
        ))}
      </div>
      {/* <div className={styles.lienNews}>
        <a id='load-more-news' href='#'>
          Load More<i className='fal fa-chevron-right'></i>
        </a>
      </div> */}
    </Inview>
  )
}

const Item = ({ content, i }) => {
  const { slug, title, image } = content
  const titleShorter = title.substr(0, 80) + '...'
  return (
    <div
      className={`${styles.newsItem} in in-fade-in-down stagger-${
        400 + i * 100
      }`}
    >
      <div className={styles.image}>{image && <ImageWrap image={image} />}</div>
      <h2>
        <LinkWrap to={`/news/${slug}`}>{titleShorter}</LinkWrap>
      </h2>
    </div>
  )
}

export default Block

export const fragmentEn = graphql`
  fragment GridThumbsEn on MarkdownRemarkFrontmatterEnBlocks {
    title
  }
`
export const fragmentZh = graphql`
  fragment GridThumbsZh on MarkdownRemarkFrontmatterZhBlocks {
    title
  }
`
